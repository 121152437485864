import { styled } from '@mui/material/styles';

import { CheckboxStatuses, LineProps } from '../../ProgressBar.model';

import { Typography } from '@mui/material';

export const StatusContainer = styled('div')<LineProps>(
  ({ theme, lastElement, color, numOfElements }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'unset',
    marginBottom: lastElement ? 0 : theme.spacing(6),
    height: '24px',
    '&:after': {
      content: "' '",
      display: lastElement ? 'none' : 'block',
      position: 'absolute',
      height: 'calc(24px * 2)',
      left: 'calc(24px / 2)',
      borderLeft: `2px solid ${theme.palette.border[color]}`,
      marginTop: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      width: `calc(100% / ${numOfElements})`,
      '&:after': {
        content: 'none',
      },
    },
  })
);

export const CheckboxContainer = styled('div')<LineProps>(
  ({ theme, lastElement, color, numOfElements }) => ({
    '&:after': {
      content: 'none',
    },
    [theme.breakpoints.up('md')]: {
      '&:after': {
        content: "''",
        display: lastElement ? 'none' : 'block',
        position: 'absolute',
        width: `calc(100% / ${numOfElements})`,
        top: 'calc(32px / 2)',
        borderBottom: `2px solid ${theme.palette.border[color]}`,
      },
    },
  })
);

export const Checkbox = styled('img')(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  height: '24px',
  marginRight: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    height: 'unset',
    marginRight: 0,
  },
}));

export const Status = styled(Typography)<{ status?: CheckboxStatuses }>(({ theme, status }) => ({
  marginTop: 0,
  maxWidth: 'unset',
  textAlign: 'unset',
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(3),
    maxWidth: '184px',
    textAlign: 'center',
  },
  fontWeight:
    status === CheckboxStatuses.CLEARED
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
}));
