import { styled } from '@mui/material/styles';

// Height/width calculations are based on the checkbox sizes of 32px (desktop)
// and 24px (mobile), respectively
export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
}));
