import { HttpMethods } from '../../enums/http';
import { Tags, restApi } from '../common';
import {
  SupportCenterCategoryResponse,
  SupportCenterCategoryRequest,
  SupportCenterCategoryListRequest,
  SupportCenterCategoryListResponse,
  SupportCenterArticleResponse,
  SupportCenterArticleRequest,
  SupportCenterArticleListRequest,
  SupportCenterArticlesListResponse,
} from './support-center.model';

const BASE_URL = '/api/support-center';

export const supportCenterApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.SupportCenter],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getSupportCenterCategories = builder.query<
        SupportCenterCategoryListResponse,
        SupportCenterCategoryListRequest
      >({
        query: queryParams => ({
          url: `${BASE_URL}/categories`,
          method: HttpMethods.Get,
          params: {
            pageSize: 100,
            pageIndex: 1,
            ...queryParams,
          },
        }),
        transformResponse: data => data.content,
        providesTags: [Tags.SupportCenter],
      });

      const getSupportCenterCategoryById = builder.query<
        SupportCenterCategoryResponse,
        SupportCenterCategoryRequest
      >({
        query: ({ categoryId }) => ({
          url: `${BASE_URL}/category/${categoryId}`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.SupportCenter],
      });

      const getSupportCenterArticles = builder.query<
        SupportCenterArticlesListResponse,
        SupportCenterArticleListRequest
      >({
        query: params => ({
          url: `${BASE_URL}/articles`,
          method: HttpMethods.Get,
          params,
        }),
        transformResponse: data => data.content,
        providesTags: [Tags.SupportCenter],
      });

      const getSupportCenterArticleById = builder.query<
        SupportCenterArticleResponse,
        SupportCenterArticleRequest
      >({
        query: ({ articleId }) => ({
          url: `${BASE_URL}/articles/${articleId}`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.SupportCenter],
      });

      const getSupportCenterArticlesRelated = builder.query<
        SupportCenterArticlesListResponse,
        SupportCenterArticleRequest
      >({
        query: ({ articleId }) => ({
          url: `${BASE_URL}/articles/${articleId}/related`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.SupportCenter],
      });

      return {
        getSupportCenterCategories,
        getSupportCenterCategoryById,
        getSupportCenterArticles,
        getSupportCenterArticleById,
        getSupportCenterArticlesRelated,
      };
    },
  });
