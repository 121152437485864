import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const HeaderRow = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    margin: 0,
    padding: theme.spacing(0, 9, 3),
    justifyContent: 'flex-start',
    borderTop: 'none',
  },
}));

export const LeftHeaderElement = styled(Typography)(() => ({
  minWidth: '45px',
}));

export const RightHeaderElement = styled(LeftHeaderElement)(() => ({
  minWidth: 'unset',
}));
