import React from 'react';
import { genTestIds } from '@yieldstreet/tool-kit';

export const { getTestIds, useTestIds } = genTestIds([
  'title',
  'errorIcon',
  'subtitle',
  'content',
  'cta',
  'expandableIconUp',
  'expandableIconDown',
  'desktopContainer',
  'mobileContainer',
]);

export type RowData = {
  date: string;
  title: string;
  description?: string;
  button?: boolean;
  buttonCTA?: string;
  buttonLoading?: boolean;
  buttonDisabled?: boolean;
  onCTAClick?: () => void;
  expandable?: boolean;
  startOpen?: boolean;
  children?: React.ReactChild;
  error?: boolean;
  lastRow?: boolean;
  forcedOpen?: boolean;
};

export type RowProps = {
  lastRow?: boolean;
  onCTAClick?: () => void;
};

export interface MobileStyleProps {
  light?: boolean;
  error?: boolean;
}

export interface ContentProps {
  show?: boolean;
}
