import { communicationApi } from './communication';
import { GetRecommendededOffersSourceType } from '../marketplace';

export const COMMUNICATION_BASE_URL = '/api/communication/messages';

export enum CommunicationMessageCategory {
  Important = 'IMPORTANT',
  InvestmentUpdates = 'INVESTMENT_UPDATES',
  InvestmentProgress = 'INVESTMENT_PROGRESS',
  Payments = 'PAYMENTS',
}

export enum CommunicationMessageType {
  Activity = 'ACTIVITY',
  PlatformPromotion = 'PLATFORM_PROMOTION',
}

export enum ActivityEvent {
  InvestmentRequested = 'INVESTMENT_REQUESTED',
  InvestmentRemovedFromWaitList = 'INVESTMENT_REMOVED_FROM_WAITLIST',
  InvestmentConfirmed = 'INVESTMENT_CONFIRMED',
  InvestmentActive = 'INVESTMENT_ACTIVE',
  InvestmentCancelled = 'INVESTMENT_CANCELLED',
  InvestmentUpdate = 'INVESTMENT_UPDATE',
  DistributionUpdate = 'DISTRIBUTION_UPDATE',
  DistributionPayment = 'DISTRIBUTION_PAYMENT',
  DepositPayment = 'DEPOSIT_PAYMENT',
  DealMatured = 'DEAL_MATURED',
  RecurringPaymentWarning = 'RECURRING_PAYMENT_WARNING',
  MomentsOfJoy = 'MOMENTS_OF_JOY',
  CustomUpdate = 'CUSTOM_UPDATE',
  CustomActivity = 'CUSTOM_ACTIVITY',
}

export enum ActivityEventType {
  Default = 'DEFAULT',
  QuarterlySnapshot = 'QUARTERLY_SNAPSHOT',
  QuarterlySnapshotReengagement = 'QUARTERLY_SNAPSHOT_REENGAGEMENT',
  WalletBalancePromo = 'WALLET_BALANCE_PROMO',
}

export enum ActivityMessageTemplateId {
  MomentsOfJoyRegistration = 'moments-of-joy-registration',
  MomentsOfJoyFirstTimeInvestment = 'moments-of-joy-first-time-investment',
}

export type MessageConfiguration = {
  activityEvent?: ActivityEvent;
  activityEventType?: ActivityEventType;
  category?: CommunicationMessageCategory;
  recommendationSource?: GetRecommendededOffersSourceType;
  amount?: string;
  date?: string;
  id?: string;
  investmentId?: string;
  investorAccountId?: string;
  noteTitle?: string;
  noteUrlHash?: string;
  text?: string;
  title?: string;
  userId?: string;
  userIds?: string;
  version?: string;
  waitlisted?: boolean;
  subTitle?: string;
  migrationId?: string;
  isMigrated?: boolean;
  campaignName?: string;
  componentType?: string;
  location?: string;
  path?: string;
  linkText?: string;
  icon?: string;
};

export type MessageTemplateConfiguration = {
  textTemplate?: string;
  titleTemplate?: string;
  version?: string;
  subTitleTemplate?: string;
};

export type CommunicationMessage = {
  configuration: MessageConfiguration;
  dismissed: boolean;
  lastUpdateId: string;
  messageId: string;
  messageSpecId: string;
  messageTemplateConfiguration: MessageTemplateConfiguration | null;
  messageTemplateId: string | null;
  messageType: CommunicationMessageType;
  publishedAt: string;
  read: boolean;
  sortDate: string;
  sortKey: string;
  startAt: string;
  userId: string;
  sortOrder: null;
};

export type CommunicationState = ReturnType<typeof communicationApi.reducer>;
export interface CommunicationMessageModified extends CommunicationMessage {
  messageArgs: FetchMessagesApiRequestParams;
  seen: Boolean;
}
export interface FetchMessagesApiResponse {
  currentPage: number;
  items: CommunicationMessageModified[];
  totalCount: number;
  totalPages: number;
}

export interface FetchMessagesApiAnchor {
  id: string;
  timestamp: string;
}

export enum FetchMessagesUpdateStrategy {
  AGGREGATE = 'AGGREGATE',
  LIVE_ONLY = 'LIVE_ONLY',
  INITIAL_ONLY = 'INITIAL_ONLY',
}

export interface FetchMessagesApiRequestParams {
  anchor?: FetchMessagesApiAnchor;
  authToken?: string;
  category?: CommunicationMessageCategory | null;
  dismissed?: boolean;
  investorAccountId?: string;
  limit?: number;
  messageIds?: string[];
  noteUrlHashes?: string[];
  page?: number;
  read?: boolean;
  refreshTimeStamp?: string;
  types?: CommunicationMessageType[];
  updates?: FetchMessagesUpdateStrategy;
  userId?: number | string;
}

export type FetchMessagesApiRequestParamsKey = keyof Omit<FetchMessagesApiRequestParams, 'types'>;

export interface ReadMessageApiResponse {
  updated: boolean;
  read: boolean;
  messageId: string;
}
export interface ReadMessageApiRequestParams {
  messageSpecId: string;
  messageArgs?: FetchMessagesApiRequestParams;
  read: boolean;
}
export interface ReadMessagesApiResponse {
  responses: ReadMessageApiResponse[];
}

export interface ReadMessagesApiRequestParams {
  messageSpecIds: string[];
  messageArgs?: FetchMessagesApiRequestParams;
  read: boolean;
}
export interface DismissMessageApiResponse {
  updated: boolean;
  dismissed: boolean;
  messageId: string;
}
export interface DismissMessageApiRequestParams {
  messageSpecId: string;
  messageArgs: FetchMessagesApiRequestParams;
  dismissed: boolean;
}
export interface DismissMessagesApiResponse {
  responses: DismissMessageApiResponse[];
}
export interface DismissMessagesApiRequestParams {
  messageSpecIds: string[];
  messageArgs: FetchMessagesApiRequestParams;
  dismissed: boolean;
}
