import { styled } from '@mui/material/styles';
import { Typography, Box, Stack } from '@mui/material';

import { RowProps, MobileStyleProps, ContentProps } from './ProgressTableRow.model';

export const Row = styled('div')<RowProps>(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.default}`,
  margin: theme.spacing(0, 3),
  padding: theme.spacing(5, 2),
  [theme.breakpoints.up('md')]: {
    margin: 0,
    padding: theme.spacing(5, 9),
  },
}));

export const Desktop = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export const Mobile = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const LeftElement = styled(Stack)(() => ({
  minWidth: '45px',
  justifyContent: 'space-between',
}));

export const ErrorIcon = styled('img')(({ theme }) => ({
  height: '18px',
  marginRight: theme.spacing(4),
  marginTop: theme.spacing(0),
  [theme.breakpoints.up('md')]: {
    height: '24px',
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(1),
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  maxWidth: '660px',
  marginTop: theme.spacing(5),
}));

export const DesktopSubTitle = styled(Description)`
  margin-top: 0;
`;

export const MobileSubTitle = styled(Description)`
  margin-top: 23px;
`;

export const IconHolder = styled('div')<MobileStyleProps>(({ error }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  '&:hover': {
    cursor: 'pointer',
  },
  ...(error && { minWidth: '47px' }),
}));

export const AnimatedContent = styled(Box)<ContentProps>(({ show }) => ({
  overflow: 'hidden',
  height: 'auto',
  maxHeight: show ? '1000px' : '0px',
  transition: 'max-height 0.7s ease',
}));
