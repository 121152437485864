import { HttpMethods } from '../../enums';
import { restApi } from '../common';

import {
  CreateFootprintTokenParams,
  CreateFootprintTokenResponse,
  FootprintValidationParams,
  FootprintValidationResponse,
  RefreshIdentityVerificationStatusParams,
  RefreshIdentityVerificationStatusResponse,
} from './identity.model';

const BASE_URL = `/api/investor/public/investor-entity`;

export const identityApi = restApi.injectEndpoints({
  endpoints: builder => {
    const validateFootprintToken = builder.mutation<
      FootprintValidationResponse,
      FootprintValidationParams
    >({
      query: ({ investorEntityId, token }) => ({
        url: `${BASE_URL}/${investorEntityId}/identity/footprint/validate`,
        method: HttpMethods.Post,
        data: {
          token,
        },
      }),
    });

    const createFootprintToken = builder.mutation<
      CreateFootprintTokenResponse,
      CreateFootprintTokenParams
    >({
      query: ({ investorEntityId }) => ({
        url: `${BASE_URL}/${investorEntityId}/identity/footprint/session`,
        method: HttpMethods.Post,
      }),
    });

    const refreshIdentityVerificationStatus = builder.mutation<
      RefreshIdentityVerificationStatusResponse,
      RefreshIdentityVerificationStatusParams
    >({
      query: ({ investorEntityId }) => ({
        url: `${BASE_URL}/${investorEntityId}/identity/refresh-status`,
        method: HttpMethods.Post,
      }),
    });

    return {
      createFootprintToken,
      refreshIdentityVerificationStatus,
      validateFootprintToken,
    };
  },
});
