export enum AccountProgressSteps {
  ACCOUNT_CREATION = 'ACCOUNT_CREATION',
  IDENTITY = 'IDENTITY',
  BANK = 'BANK',
  FIRST_INVESTMENT = 'FIRST_INVESTMENT',
}
interface AccountProgressContent {
  label: string;
  caption: string;
  name: AccountProgressSteps;
  cta: string;
}

export interface AccountProgressData extends AccountProgressContent {
  isFinished?: boolean;
  showCta?: boolean;
  action: any;
}

export const AccountProgressContentMap: Record<AccountProgressSteps, AccountProgressContent> = {
  [AccountProgressSteps.ACCOUNT_CREATION]: {
    label: 'Create your first account',
    caption: 'Getting started on Yieldstreet',
    name: AccountProgressSteps.ACCOUNT_CREATION,
    cta: 'Continue',
  },
  [AccountProgressSteps.IDENTITY]: {
    label: 'Verify identity',
    caption: 'Tell us a bit about yourself',
    name: AccountProgressSteps.IDENTITY,
    cta: 'Continue',
  },
  [AccountProgressSteps.BANK]: {
    label: 'Link a bank',
    caption: 'Fund your Yieldstreet account',
    name: AccountProgressSteps.BANK,
    cta: 'Continue',
  },
  [AccountProgressSteps.FIRST_INVESTMENT]: {
    label: 'Make your first investment',
    caption:
      'Explore curated opportunities spanning real estate, private credit, private equity, and more.',
    name: AccountProgressSteps.FIRST_INVESTMENT,
    cta: 'View investment opportunities',
  },
};
