import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
  flex: '1 1 auto',
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(6),
  },
}));

export const Copy = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  alignContent: 'stretch',
  alignItems: 'stretch',
}));
