import React, { useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Box, Stack, Button } from '@mui/material';
import { MuiMarkdown } from 'mui-markdown';
import { BaseProps } from '../../../../types/components/base';
import checkboxError from '../assets/checkbox_error.svg';
import { RowData, useTestIds, getTestIds } from './ProgressTableRow.model';
import {
  Row,
  Desktop,
  Mobile,
  LeftElement,
  ErrorIcon,
  Description,
  DesktopSubTitle,
  IconHolder,
  AnimatedContent,
  MobileSubTitle,
} from './ProgressTableRow.style';

export const ProgressTableRow = ({
  date,
  title,
  description,
  button = false,
  buttonCTA,
  onCTAClick,
  expandable,
  buttonLoading,
  buttonDisabled = false,
  startOpen,
  children,
  error = false,
  lastRow,
  forcedOpen,
  ...props
}: RowData & BaseProps) => {
  const [show, setShow] = useState<boolean>(startOpen || false);
  const testIds = useTestIds(props);

  const handleShow = () => {
    if (forcedOpen || show) return;

    setShow(true);
  };

  const handleArrowClick = () => {
    if (forcedOpen) return;

    setShow(!show);
  };

  return (
    <Row lastRow={lastRow} onClick={handleShow} {...testIds.base.attr}>
      <Desktop {...testIds.desktopContainer.attr}>
        <Stack direction="row">
          <LeftElement direction="row">
            {/* dates shouldn't show while the BE sends them wrong
              leaving it there so its simple to revert once fixed */}
            {/* {false && date && (
              <Typography variant="body1" color="textSecondary">
                {date}
              </Typography>
            )} */}
            {error && <ErrorIcon src={checkboxError} {...testIds.errorIcon.attr} />}
          </LeftElement>
          <Box>
            <Typography variant="body1" fontWeight="medium" {...testIds.title.attr}>
              {title}
            </Typography>
            {description && (
              <DesktopSubTitle variant="body1" color="textSecondary" {...testIds.subtitle.attr}>
                <MuiMarkdown>{description}</MuiMarkdown>
              </DesktopSubTitle>
            )}
            {!!expandable && children && (
              <AnimatedContent show={show}>
                <Description {...testIds.content.attr}>{children}</Description>
              </AnimatedContent>
            )}
            {forcedOpen && (
              <AnimatedContent show={show}>
                <Description {...testIds.content.attr}>{children}</Description>
              </AnimatedContent>
            )}
          </Box>
        </Stack>
        {button && (
          <Box>
            <LoadingButton
              {...testIds.cta.attr}
              sx={{ mt: 5 }}
              onClick={onCTAClick}
              loading={buttonLoading}
              disabled={buttonDisabled}
            >
              {buttonCTA}
            </LoadingButton>
          </Box>
        )}
        {expandable && (
          <IconHolder onClick={handleArrowClick}>
            {show && <ExpandLessIcon {...testIds.expandableIconUp.attr} />}
            {!show && <ExpandMoreIcon {...testIds.expandableIconDown.attr} />}
          </IconHolder>
        )}
      </Desktop>
      <Mobile {...testIds.mobileContainer.attr}>
        <Stack>
          <Box>
            <Typography variant="body1" fontWeight="medium" {...testIds.title.attr}>
              {title}
            </Typography>
            <AnimatedContent show={show}>
              {date && (
                <Typography
                  sx={{ mt: 2 }}
                  variant="body2"
                  color="textSecondary"
                >{`Updated on ${date}`}</Typography>
              )}
              {description && (
                <MobileSubTitle color="textSecondary" {...testIds.subtitle.attr}>
                  <MuiMarkdown>{description}</MuiMarkdown>
                </MobileSubTitle>
              )}
              {children && (
                <Description variant="body1" fontWeight="medium" {...testIds.content.attr}>
                  {children}
                </Description>
              )}
              {button && (
                <Box>
                  <Button {...testIds.cta.attr} sx={{ mt: 6 }} onClick={onCTAClick}>
                    {buttonCTA}
                  </Button>
                </Box>
              )}
            </AnimatedContent>
          </Box>
        </Stack>
        <IconHolder error={error} onClick={handleArrowClick}>
          {error && <ErrorIcon src={checkboxError} {...testIds.errorIcon.attr} />}
          {show && <ExpandLessIcon {...testIds.expandableIconUp.attr} />}
          {!show && <ExpandMoreIcon {...testIds.expandableIconDown.attr} />}
        </IconHolder>
      </Mobile>
    </Row>
  );
};

ProgressTableRow.getTestIds = getTestIds;
